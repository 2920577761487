'use client';
import React, { useState } from 'react';
// @ts-ignore
import { createClient } from '@/lib/supabase/component';
import { toast } from 'react-toastify';
import getMixPanelClient from '../lib/mixpanelBrowserClient';
import Link from 'next/link';
import { AnimatePresence, motion } from 'framer-motion';
import { XMarkIcon } from '@heroicons/react/24/outline';
import LoadingFullScreen from '../shared/LoadingFullScreen';

const mixpanelClient = getMixPanelClient();

interface WaitlistModalProps {
  handleClose: () => void;
}

/*
.##.....##..#######..########.....###....##......
.###...###.##.....##.##.....##...##.##...##......
.####.####.##.....##.##.....##..##...##..##......
.##.###.##.##.....##.##.....##.##.....##.##......
.##.....##.##.....##.##.....##.#########.##......
.##.....##.##.....##.##.....##.##.....##.##......
.##.....##..#######..########..##.....##.########
*/
const WaitlistModal: React.FC<WaitlistModalProps> = ({ handleClose }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  // !TODO NEEDS TO BE MOVED TO SERVER ACTION CAN"T USE SUPABASE CLIENT HERE (in useclint)
  // const handleSubmit = async () => {
  //   try {
  //     const SupabaseClient = createClient();
  //     setIsLoading(true);
  //     const addToWaitlist = await SupabaseClient.from('mailing_list').upsert([
  //       { email },
  //     ]);
  //     setIsLoading(false);
  //     if (addToWaitlist.error) {
  //       console.error(addToWaitlist.error);
  //       toast.error('Error adding to waitlist', {
  //         toastId: 'WAITLIST_ERROR',
  //         theme: 'dark',
  //       });
  //     } else {
  //       mixpanelClient.track('waitlist_add', { email: email });
  //       setEmail('');
  //       handleClose();
  //       toast.success('Added to the waitlist!', {
  //         toastId: 'WAITLIST_SUCCESS',
  //         theme: 'dark',
  //       });
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.error(error);
  //     toast.error('Error adding to waitlist', {
  //       toastId: 'WAITLIST_ERROR',
  //       theme: 'dark',
  //     });
  //   }
  // };

  return (
    <>
      {isLoading && <LoadingFullScreen />}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        // onClick={() => {
        //   handleClose();
        // }}
      >
        <div className="fixed inset-0 flex items-center justify-center bg-shades-baseDark bg-opacity-10 z-[100]">
          <div
            className="bg-colors-lavender p-10 py-9 rounded shadow w-full max-w-sm sm:max-w-lg z-[101] relative top-[-150px]"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="relative bottom-6 left-6">
              {' '}
              {/* This div creates a new positioning context */}
              <button
                type="button"
                className="absolute top-0 right-0 rounded-md text-shades-base hover:text-shades-surface"
                onClick={handleClose}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <h3 className="text-[18px] font-semibold tracking-[0.15em] text-shades-baseDark">
              {/* CareerMapper.ai */}
              <div className="flex justify-center">
                INTERVIEW
                <div className="w-[4px]" />
                MAPPER
              </div>
            </h3>
            <h2 className="text-2xl mt-2 sm:text-[54px] text-shades-base text-center font-light mb-[2px]">
              Coming Soon!
            </h2>
            <p className="text-lg text-shades-baseLight/95 mb-2 mt-10 text-center">
              Be the first to know when it&apos;s ready:
            </p>
            <div className="mt-[12px] mb-2 relative flex rounded-md shadow-sm z-0">
              <div className="relative flex flex-grow items-stretch z-0">
                <input
                  type="email"
                  placeholder="john@gmail.com"
                  value={email}
                  onChange={handleEmailChange}
                  className="bg-white/90 block relative w-full rounded-none rounded-l-md z-0 border-0 py-1.5 text-shades-base ring-1 ring-inset ring-white/20 placeholder:text-shades-surfaceLight/50 focus:ring-1 focus:ring-inset focus:ring-white/50 text-[16px] sm:leading-[30px]  backdrop-blur-md pl-[18px] shadow-md"
                  autoFocus // Add autoFocus attribute
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      // handleSubmit();
                    }
                  }}
                />
              </div>
              <button
                aria-label="submit"
                type="button"
                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-[12px] py-[9px] font-bold  bg-colors-lavender brightness-75 hover:brightness-[0.8]  text-white text-[18px] leading-[26px]  group shadow-slate-800/250 shadow-md hover:shadow-lg ring-shades-baseDark/50 ring-1 transition-all duration-300"
                onClick={() => {
                  // handleSubmit();
                }}
              >
                <span className=" px-[4px]">Join Waitlist</span>
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

/*
.########.########....###.....######..########.########.
....##....##.........##.##...##....##.##.......##.....##
....##....##........##...##..##.......##.......##.....##
....##....######...##.....##..######..######...########.
....##....##.......#########.......##.##.......##...##..
....##....##.......##.....##.##....##.##.......##....##.
....##....########.##.....##..######..########.##.....##
*/
// todo: refactor this teaser tobe stateless and use query params
const WaitlistTeaser: React.FC = ({}) => {
  const [showWaitlist, setShowWaitlist] = useState(false);
  return (
    <>
      {showWaitlist && (
        <AnimatePresence>
          <WaitlistModal
            handleClose={() => {
              setShowWaitlist(false);
            }}
          />
        </AnimatePresence>
      )}
      <p className="text-slate-100/60 z-0 text-[15.5px] leading-6 mt-11 text-left">
        Getting ready for interviews? Try our{' '}
        <span>
          <button
            className="underline cursor-pointer hover:text-white transition-all duration-200"
            onClick={() => {
              setShowWaitlist(true);
            }}
          >
            Interview Mapper!
          </button>
        </span>
      </p>
    </>
  );
};

export default WaitlistTeaser;
