'use client';

import React, { LegacyRef } from 'react';
import { toast } from 'react-toastify';

import LoadingFullScreen from '../shared/LoadingFullScreen';

import { FaProjectDiagram } from 'react-icons/fa';
import { usePathname, useRouter } from 'next/navigation';
import { slugify } from '../lib/slugify';
import { on } from 'events';
import type { CareerMap, CareerMapPreDB } from '../types';
import Link from 'next/link';

// const testError = async () => {
//   throw new Error('Test Error');
// };

export const SampleCard = ({ text, handleClick }: any) => {
  // React.useEffect(() => {
  //   testError();
  // });

  return (
    <>
      <button
        className={`group backdrop-blur-md bg-transmarent flex-1 p-4 border-2 border-white/20 border-dashed hover:shadow-xl  rounded-lg text-white/60 transition-all duration-300 cursor-pointer hover:text-white/90 hover:bg-colors-green/80 hover:ring-emerald-600/50 hover:border-transparent hover:border-solid hover:backdrop-blur-lg group text-[17.5px] leading-[27px]`}
        onClick={() => {
          // error handled in handleClick
          handleClick(text);
        }}
        value={text}
        name="objectiveText"
        type="button"
      >
        <div className="flex flex-col items-center justify-center h-full transition-all duration-100 delay-0">
          <p className="text-center group-hover:text-shades-baseDark transition-all duration-100">
            {text}
          </p>
        </div>
      </button>
    </>
  );
};

const CreateMapForm: React.FC<{}> = () => {
  const [objectiveText, setObjectiveText] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const searchBarRef = React.useRef(null);
  const router = useRouter();
  // temporary until useActionStatus is released
  const onSubmit = async (objective: any) => {
    let createdMap: { error?: string };

    try {
      setIsLoading(true);

      if (objective === '') {
        toast.warning('Please enter a job title', {
          toastId: 'EMPTY JOB TITLE',
          theme: 'dark',
        });
        setIsLoading(false);
        return;
      }
      // todo navigate to map page instead
      router.push(`/map/${slugify(objective)}/overview`);
      // createdMap = await getNewMap(objective);
    } catch (error) {
      console.log('test?');
      console.error('Error Creating Map');
      setIsLoading(false);
      toast.error('Error Creating Map: ' + objective, {
        toastId: 'ERROR_CREATING_MAP',
        theme: 'dark',
      });
      return;
    }
    // if (createdMap && 'error' in createdMap) {
    //   setIsLoading(false);
    //   console.error('Error Creating Map');
    //   return toast.error('Error Creating Map: ' + objective, {
    //     toastId: 'ERROR_CREATING_MAP',
    //     theme: 'dark',
    //   });
    // }
  };

  return (
    <>
      {isLoading && <LoadingFullScreen />}
      <form
        role="search"
        className="w-[564px] z-10"
        // action={onSubmit}
      >
        <label
          htmlFor="objectiveText"
          className="block text-[15.5px] font-medium leading-[26px] text-white/75 relative top-[7px] left-[1px]"
        >
          Job Title:
        </label>
        <div className="mt-[9px] relative flex rounded-md shadow-sm z-0">
          <div className="relative flex flex-grow items-stretch z-0">
            <input
              type="text"
              name="objectiveText"
              id="objectiveText"
              value={objectiveText}
              onChange={(e) => setObjectiveText(e.target.value)}
              ref={searchBarRef}
              className="block relative w-full rounded-none rounded-l-md z-0 border-0 py-1.5 text-white/90 ring-1 ring-inset ring-white/20 placeholder:text-white/25 focus:ring-1 focus:ring-inset focus:ring-white/50 text-[17.5px] sm:leading-[32px] bg-white/10 backdrop-blur-md pl-[18px] shadow-md"
              placeholder="Cognitive Psychologist"
              autoFocus // Add autoFocus attribute
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  onSubmit(objectiveText);
                }
              }}
            />
          </div>
          <button
            aria-label="submit"
            type="button"
            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-[12px] py-[9px] font-bold  bg-colors-orange  text-shades-baseDark text-[18px] leading-[26px]  group hover:bg-yellow shadow-slate-800/250 hover:shadow-lg  hover:ring-0 border-amber-700 ring-shades-baseDark/50 ring-1 transition-all duration-300 shadow-colors-orange
            shadow-[0px_0px_40px_-5px_colors-orange]"
            // "shadow-colors-orange shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_15px_#08f,0_0_30px_#08f]"
            onClick={() => {
              onSubmit(objectiveText);
              // todo navigate to map page instead
              // navi
            }}
          >
            {/* <FaProjectDiagram
              className="h-5 w-5 mt-0.5 text-shades-baseLight"
              aria-hidden="true"
            /> */}
            <span className="text-shades-baseDark px-[4px]">Create Map</span>
          </button>
          {/* <Link href={`/map/${slugify(objectiveText)}/overview`}>
            <span className="text-shades-baseDark px-[4px]">Overview</span>
          </Link> */}
        </div>
        <section
          className="flex gap-[20px] mt-[22px]"
          aria-label="job title autocomplete suggestions"
        >
          <SampleCard text="Digital Marketer" handleClick={onSubmit} />
          <SampleCard text="Front-end Developer" handleClick={onSubmit} />
          <SampleCard text="Classical Pianist" handleClick={onSubmit} />
        </section>
      </form>
    </>
  );
};

export default CreateMapForm;
