import mixpanel from 'mixpanel-browser';

let hasBeenInitialized = false;
let mixPanelStub = null;
const getMixPanelClient = () => {
  if (hasBeenInitialized) {
    return mixPanelStub || mixpanel;
  } else {
    if (process.env.NODE_ENV === 'development') {
      mixPanelStub = {
        track: (event, props) => {
          console.log('MIXPANEL EVENT: ', event, props);
        },
        set: (distinct_id, props) => {
          console.log('MIXPANEL SET: ', distinct_id, props);
        },
        people: {
          increment: (distinct_id, prop, value) => {
            console.log(
              'MIXPANEL PEOPLE INCREMENT: ',
              distinct_id,
              prop,
              value
            );
          },
        },
        identify: (distinct_id) => {
          console.log('MIXPANEL IDENTIFY: ', distinct_id);
        },
      };
    } else {
      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
        track_pageview: true,
      });
    }
    return mixPanelStub || mixpanel;
  }
};

export default getMixPanelClient;
